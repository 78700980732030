.title {
    color: var(--grey-dk);
    font-size: var(--font-big);
    line-height: 21px;
    margin: 25px 0 10px 0;
}

.body {
    color: var(--grey-lt);
}
