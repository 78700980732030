.price {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: var(--font-small);
    /* This ensures the price isn't squished by neighboring items in the parent flex container */
    min-width: 120px;
}

.amount {
    font-size: 24px;
    color: var(--grey-dk);
    margin-bottom: 4px;
}

.symbol {
    color: var(--grey-lt);
}

.original-price-container {
    font-size: var(--font-small);
    margin-bottom: 4px;
}

.original-price {
    text-decoration: line-through;
    color: var(--grey-lt);
}

.discount {
    color: var(--green);
    text-decoration: none;
    margin-left: 4px;
}
