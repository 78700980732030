.section {
    margin-top: 34px;
    margin-bottom: 14px;
}

.status-section-container {
    height: 70px;
    border-bottom: 1px solid var(--off-white-dk);
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}
