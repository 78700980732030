.textfield {
    color: var(--grey);
    font-size: var(--font-small);
    padding: 4px 8px;
    height: 34px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    transition: color 200ms linear;
}

.textfield:hover:not(:disabled) {
    border-color: var(--grey-lt) !important; /* This overrides inline styles */
}

.textfield--big {
    font-size: 18px;
    padding: 4px 12px;
    height: 51px;
}

.textfield--small {
    height: 31px;
}

.textfield--medium {
    font-size: 15px;
}

.textfield--expand {
    height: 100%;
    width: 100%;
}

.textfield--no-border {
    border: none;
}

.textfield--no-padding {
    padding: 0;
}

.textfield--bordered {
    composes: border from '../border/border.css';
}

.textfield::placeholder {
    color: var(--placeholder);
}

.textfield:disabled {
    background-color: var(--off-white);
    opacity: 0.6;
}

.textfield[type='number'] {
    text-align: right;
}

.textfield--multiline {
    composes: textfield;
    padding: 8px;
    height: 96px;
    resize: none;
}

.textfield--success {
    color: var(--green);
}

.textfield--error {
    composes: border--error from '../border/border.css';
}

.textfield--error-message::placeholder {
    color: var(--rose);
    opacity: 1;
}

.unit {
    height: 20px;
    margin: 6px 0 6px -32px;
    padding-left: 8px;
    border-left: 0.5px solid var(--form-border);
}

.textfield--fit-content {
    height: fit-content;
}

.textfield--text-align-left {
    text-align: left;
}

.textfield--text-align-center {
    text-align: center;
}

.textfield--text-align-right {
    text-align: right;
}
