.title-text {
    margin-right: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.title-text > :first-child {
    margin-bottom: 10px;
    transform: translateY(-3px); /* Visually centered, to adjust for acorn top */
}
