.quote-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    padding: 8px 6px;
}

.quote-row:hover {
    border-color: var(--placeholder);
    cursor: pointer;
}

.quote-row-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
