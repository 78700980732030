.nav-container {
    margin-left: 0;
    margin-top: 0;
    display: flex;
    justify-content: center;
}

:global(#settings-nav),
:global(#settings-nav-disabled) {
    float: left;
    display: flex;
    flex-grow: 1;
    width: 168px;
    min-height: 100vh;
    height: 100%;
}

.settings-button {
    display: flex;
    justify-content: center;
    width: 146px;
    right: 3px;
}
