.renewal-text {
    font-size: var(--font-normal);
    color: var(--grey-lt);
    margin-top: 8px;
}

.renewal-text--warn {
    composes: renewal-text;
    color: var(--rose-dk);
}
