.section-label {
    font-size: 12px;
    line-height: 21px;
    font-weight: 500;
    padding-bottom: 6px;
    margin-bottom: 8px;
    color: var(--grey);
    text-transform: uppercase;
    border-bottom: 1px solid var(--off-white-dk);
}

.section-label.no-bottom-border {
    border-bottom: none;
    padding-bottom: 0;
}

.section-label.no-bottom-margin {
    margin-bottom: 0;
}
