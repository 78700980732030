@hide-sidebar-width: 1200px;
@setup-sidebar-width: 20%;

html, body {
	width: 100%;
	height: 100%;
	min-height: 100%;
}

body {
	display: flex;
	flex-direction: column;
	background: @off-white;
	padding-top: 0;
	box-sizing: border-box;
	#util > .transition(padding-top, 500ms, ease-in-out, 400ms);

	.notifies-container {
		position: fixed;
		bottom: 16px;
		z-index: 1000;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.list-standard-header,
	.box-header h3 {
		font-weight: 500;
		-webkit-font-smoothing: antialiased;
	}
	.list-standard-header {
		-webkit-font-smoothing: subpixel-antialiased;
	}

	//These display rules should not be easy to override.
	.needs-admin,
	.needs-admin-inline { display: none !important; }
	&[data-is-admin] {
		.needs-admin { display: block !important; }
		.needs-admin-inline { display: inline !important; }
	}
}

body#login {
	.2x('/include/images/layout/body-bg-pinstripes.png', "repeat", 56px, 56px);
}

/* ======================================= */
/* = Styles used for overall page layout = */
/* ======================================= */

.page-header-title { // move to subheader
	font-size: 24px;
	line-height: 24px;
	color: @grey-dk;
}

.page-header-bar {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: space-between;

    h3 {
        font-family: @headline-font;
        font-size: 21px;
        font-weight: bold;
        line-height: 34px;
        color: @grey-dk;
        max-width: 70%;
    }
}

#page-header {
	font-size: 21px;
	font-weight: bold;
	line-height: 26px;
	color: @grey-dk;
	background-color: white;
	margin-left: 8px;
	min-height: 31px;
	padding: 10px 20px;
	border-left: 140px solid transparent;
	border-left-width: 140px !important;
	border-bottom: 1px solid @off-white-dk;
}

body {

	&.manage {
		.page-sidebar {
			float: left;
		}
		&:not(.list),
		&.account-settings,
		&.account-email,
		&.account-notifications,
		&.account-integrations {
			.page-flow-area {
				box-sizing: border-box;
				float: left;
				height: 100%;
				width: 100%;
				max-width: 1200px;
				@media (min-width: 1400px) {
					border-radius: 4px 4px 0 0;
					border-right: 1px solid @off-white-dk;
				}
			}
		}
		&.list {
			.page-main-content {
				padding-right: 16px;
			}
		}

		&:not(.fixed-width) {
			.page-sidebar {
				display: none;
				@media (min-width: @hide-sidebar-width) {
					display: block;
				}
			}
			.page-flow-area {
				width: 100%;
				@media (min-width: @hide-sidebar-width) {
					width: ~"calc(100% - @{setup-sidebar-width})";
					max-width: 1100px;
				}
			}
			.narrow-page-flow-area {
				width: 100%;
			 	@media (min-width: @hide-sidebar-width) {
				width: ~"calc(100% - 25%)";
				max-width: 1100px;
				}
			}
		}
	}

	&#myaccount {
		.page-main-content {
			height: 100%;
			padding-right: 0;
			padding-left: 0;
		}

		&.account-email-settings {
			.page-flow-area {
				border-right: none;
			}
		}

	}

	&.settings {
		#page-content.combined-settings {
			display: table;
		}

		.settings-page-container {
			display: table-row;
		}

		.page-nav-sidebar {
			display: table-cell;
			width: 200px;
			height: 100%;
		}

		.page-main-content {
			display: table-cell;
			vertical-align: top;
		    padding: 16px 16px 0 16px;
			width: 100%;

			.notify-messages {
				margin-left: 16px;
			}
		}

		&#sales-automation,
		&#billing,
		&#trash {
			// IE11 has a layout bug when this is position relative
			&:not([data-is-ie11]) {
				.page-main-content {
					position: relative;
				}
			}
		}
	}
}

body.variant-nochrome {
	padding-top: 0;

	#page-content {
		border: none;
	}
}

body.reports {
	#page-content {
		.interactive-table {
			border: none;
			border-radius: 0;
			th,
			td {
				padding: 0 20px;
			}
		}
	}
}

body[data-is-trial] {
	#app-react-mount {
		margin-top: 45px;
		min-height: ~"calc(100% - 45px)";
	}
}

body[data-is-trial-2025] {
	#app-react-mount {
		border-top: 101px solid transparent;
		min-height: ~"calc(100% - 101px)";
	}
}

.page-sidebar {
	float: right;
	top: 0;
	right: 0px;
	width: @page-sidebar-width;
	.box-header h3 {
		overflow: hidden;
		white-space: nowrap;
	}
}
.page-flow-area { float: left; }
.page-flow-area.all-users {
	.help-box-container .help-box:after {
		display: none;
	}

	.help-box-container .help-box {
		padding: 24px 24px 16px 24px;
	}
}

.layout-alternate {
	.page-sidebar {
		width: @page-alternate-sidebar-width;
	}
	.page-flow-area {
		width: @page-alternate-content-width;
	}
}

/* =========== */
/* = sidebar = */
/* =========== */

.sidebar-box {
	border-radius: 3px; // needed for box-shadow below
	box-shadow: 0 0 0 2px rgba(255,255,255,0.8);
	margin-bottom: 20px;

	&.variant-border-collapse { // allow body parts of the box
		border: none;
		.box-header { border: 1px solid @grey-dk; } /* TODO: standardize color */
	}
	&.state-collapsed {

	}
	.box-section-wrap {
		background: @white;
		border: 1px solid @off-white-dk;
		border-top: none;
		border-radius: 0 0 3px 3px;

		> a:first-child > .box-section {
			border-top: none;
		}
	}

	.box-section {
		border-top: 1px solid @off-white-dk;
		padding: 5px 10px;

		a {
			text-decoration: inherit;
		}
		.form-button {
			display: none;
		}
		&.state-selected .form-button {
			display: block;
		}
		&.box-featured {
			border-top: none;
			border-bottom: none;
			padding: 0;
			a.button-add-link {
				background-position: 8px 50%;
				background-size: 18px;
				display: block;
				padding: 7px 10px;
				padding-left: 30px !important;
				opacity: 0.9;
				background-color: @color-list-selected;

				&:hover {
					background-color: @color-list-hovered;
					opacity: 1;
				}
			}
		}
		&.variant-disclosure {
			cursor: pointer;
			padding: 8px 16px;
			.count {
				font-weight: normal;
				font-size: 12px;
				font-style: normal;
				line-height: 17px;
				color: @grey;
				float: right;
				margin-right: 8px;
			}
			.icon { #icon > .disclosure; float: right; margin-top: 2px;}
			.small-note {
				font-size: 11px;
				display: block;
			}
			&:hover {
				background-color: @color-list-hovered;
				a {
					color: @grey-dk;
				}
			}
			&.state-selected {
				cursor: default;
				background-color: @color-list-selected;
				a {
					cursor: default;
					color: @grey-dk;
				}
				.icon { #icon > .disclosure-selected }
			}
		}
		&.variant-keyvalue {
			.key {
				font-weight: 500;
			}
			.value {
				float: right;
			}
		}
	}
	.box-empty-placeholder {
		color: @grey-lt;
		font-size: 15px;
		text-align: center;
		padding-top: 18px;
		padding-bottom: 18px;

		a {
			color: @grey-lt;
			text-decoration: underline;
		}

		&.variant-small { font-size: 13px; }
	}
	.box-meta {
		color: @grey; /* TODO: standardize color */
		font-size: 12px;
	}
}
.sidebar-box.pro-upsell {
	text-align: center;
	background: @slate-blue-lt;
	box-shadow: none;
	position: relative;
	.box-section {
		padding: 10px;
	}
	.feature-pro-badge {
		position: absolute;
		top: 10px;
		right: 10px;
	}
	.pro-upsell-graphic {
		.2x('@{img-root}/plan-pro/pro-upsell-graphic.png', "repeat", 114px, 72px);
		width: 114px;
		height: 72px;
		margin: 10px auto;
	}
	p {
		font-size: @small;
		line-height: 18px;
		margin: 0 0 6px 0;
		&.box-section-title {
			font-size: @normal;
			font-weight: 500;
			color: @grey-dk;
		}
	}
}
.browser-ie .sidebar-box .box-section a {
	text-decoration: none;
}

.browser-webkit .box-featured textarea { margin: 0 0 -3px 0; }

// list-standard-topper, visually similar to box-header. title text should be in an h3
.list-standard-topper,
.box-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
	padding: 0 8px;
	color: @grey-dk;
	background-color: @off-white-dk;
	border: 1px solid @form-border;
	#util > .rounded-corners(3px, top);
		h3 {
		font-size: @normal;
		display: inline;
		input.eip-input {
			font-size: 13px;
			font-weight: 500;
			outline: 0;
			border: 1px solid @off-white-dk;
			margin-top: -3px;
		}
	}
	.count,
	.box-header-count {
		color: @grey-lt;
		font-weight: normal;
	}
	.buttons {
		.button,
		.button-action {
			margin-left: 4px;
		}
	}
	.icon {
		#util > .inline-block;
		vertical-align: -3px;
		margin-right: 4px;
	}
	.icon:empty {
		margin-right: 0;
	}
	.icon.action-edit {
		margin-left: 4px;
		#icon > .box-header-edit;
		background-size: 40px 20px; // kind of a hack, overlaps with eip2.less .action-edit
	}
	.icon.action-delete {
		margin-left: 4px;
		#icon > .box-header-delete;
	}
}

.react-tooltip {
	padding: 4px 8px !important;
	font-size: 13px !important;
	z-index: 10001 !important; /* Modals are 10000 */
	border-radius: 0 !important;
}

// other sidebar-box styles
.sidebar-box.style-meta {
	.box-header {
		background: @peach-lt;
		h3 { color: @orange-dk; }
	}
}

// failure messages when loading page's entity
.failedloading {
	display: none;
}

.state-failedloading .failedloading {
	display: block;
}

#__filestack-picker {
	/* Our standard shells modal is 10,000, and we want this on top of those. */
	z-index: 10001;
}

.react-layout-page-container {
	height: 100%;
	position: relative;
	border-left: 200px solid transparent;
	z-index: 4;
}

.react-layout-page-container--widebar {
	// I don't know if composes keyword will cause problems here - this will be deleted
	// when we officially flip the flag too so not too pressed about it
	height: 100%;
	position: relative;
	border-left: 200px solid transparent;
	z-index: 4;
}

#modal-root {
	position: relative;
}

#app-react-mount {
	min-height: 100%;
	position: relative;
	border-left: 200px solid transparent;
}

#app-react-mount.widebar {
	border-left: 200px solid transparent;
}

#app-react-mount.collapsed-sidebar {
	border-left: 64px solid transparent;
}

#app-react-mount.no-sidebar {
	border: none;
}

#app-react-mount.with-navbar {
	border-top: 56px solid transparent;
	min-height: ~"calc(100% - 56px)";
}

#app-react-mount-embedded {
	min-height: 100%;
	position: relative;
}
