.container {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    position: relative;
    margin: 0;
    padding: 0 16px;
    overflow: hidden;
    flex-shrink: 0;
}

.container--bordered {
    border: 1px solid;
    border-radius: 4px;
}

.container--left-aligned-content .message {
    justify-content: flex-start;
}

.message {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: var(--font-normal, 15px);
    line-height: 19px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 16px; /* Matches outside container, equal distance from 'X' button */
}

.button-close {
    opacity: 0.35;
    padding: 8px;
}

.button-close:hover,
.button-close:focus {
    opacity: 0.8;
}

.button-close svg {
    /* This is needed to center the close button */
    display: block;
}

.container--default {
    composes: container;
    background-color: var(--yellow-bg);
    color: var(--grey);
    border-color: var(--color-banner-border-orange);
}

.container--default .button-close svg {
    fill: var(--grey);
}

.container--error {
    composes: container;
    background-color: var(--wallboard-red);
    color: var(--rose-dk);
}

.container--error .button-close svg {
    fill: var(--rose-dk);
}

.container--info {
    composes: container;
    background-color: var(--navy-bg);
    color: var(--navy-dk);
}

.container--info .button-close svg {
    fill: var(--navy-dk);
}

.container--success {
    composes: container;
    background-color: var(--green-bg);
    color: var(--lead-hovered);
}

.container--success .button-close svg {
    fill: var(--lead-hovered);
}

.container--warning {
    composes: container;
    background-color: var(--yellow-bg);
    color: var(--color-banner-border-orange);
}

.container--warning .button-close svg {
    fill: var(--color-banner-border-orange);
}

.has-cta {
    justify-content: space-between;
}
