@import 'include';

#onboarding-header {
	margin: -16px -16px 16px -16px;
	height: 55px;
	transform: translateY(0);
	transition: transform 200ms;
}

#onboarding-header.is-mounting {
	transform: translateY(-52px);
}

.list.page {
	.list-standard {
		.list-body {
			td {
				// set a specific height to compensate for rows without removers
				line-height: 20px;
				height: 20px;
			}
			.status-unprovisioned td:nth-child(-n + 3) {
				text-decoration: line-through;
			}
			td.column-report {
				text-align: right;
				white-space: nowrap;
			}
			tr.behavior-orderable {
				td.column-handlebar {
					cursor: move;
					width: 24px;

					.icon.action-handlebar {
						#icon > .pbuilder-handlebar;
					}
				}
			}
		}
	}
}

.popover-list dl.form-standard {
	dt {
		width: 70px;
		white-space: nowrap;
		overflow: hidden;
	}
	dd {
		margin-left: 78px;
		font-size: 12px;
		input.type-text,
		input.type-password {
			width: 190px;
		}
		select.type-wide {
			width: 200px;
		}
		textarea.type-description {
			width: 130px;
			height: 60px;
			resize: none;
		}
	}

	&.form-api-key {
		dt {
			width: 80px;
		}
	}
	&.product-price {
		dd,
		dt {
			margin-bottom: 18px;
		}
		dd {
			margin-left: 100px;
			text-align: left;
		}
		dt {
			width: 100px;
		}

		input[disabled='disabled'] {
			color: @grey-lt;
			text-decoration: line-through;
		}

		.per-unit {
			width: 50px;
			text-align: left;
		}
	}
}

// icons (only adding margin for icons that exist)
#list-source .list-standard-topper .icon {
	#icon > .box-header-sources;
	margin-right: 4px;
}
#list-competitor .list-standard-topper .icon {
	#icon > .box-header-competitors;
	margin-right: 4px;
}
#list-product .list-standard-topper .icon {
	#icon > .box-header-products;
	margin-right: 4px;
}

.list-standard-topper {
	.stageset-selector {
		display: inline-block;
		margin-left: 10px;
		min-width: 200px;
		.select2-container .select2-choice {
			background: @off-white !important;
		}
	}
	.stageset-label {
		display: inline;
		padding-left: 10px;
	}
}

// specific types of lists
.popover-list-delay {
	dl.form-standard dd input.input-timespan,
	dl.form-standard dd input.input-maxApplications {
		width: 40px;
	}
}

.popover-list-mobile dl.form-standard dd.mobile-udid {
	font-size: 9px;
	width: 200px;
	#util > .excerpt-crop > .ellipsis;
}
.popover-list-custom-field {
	.custom-field-indexable {
		border-bottom: none;
		border-top: 1px solid @off-white-dk;
		padding-bottom: 0;
	}
	.custom-field-multiple {
		padding-top: 0;
		.form-standard {
			dt {
				width: 95px;
			}
			dd {
				margin-left: 97px;
			}
		}
	}
	.custom-field-choice {
		border-bottom: none;
		.custom-field-choice-list {
			margin-top: 8px;
		}
		span {
			font-weight: 500;
		}
		ul {
			max-height: 130px;
			overflow: auto;
		}
		li {
			line-height: 21px;
			.optionRemove {
				cursor: pointer;
				#util > .inline-block;
				#icon > .subtract-circle;
				vertical-align: -20%;
				margin-right: 5px;
			}
		}
	}
}

.popover-list-product {
	dd input.product-type {
		margin-left: 20px !important;
	}
	.product-price dd {
		input {
			width: 90px !important;
		}
		.market-price-checkbox input {
			width: 18px !important;
		}
	}
	.per-unit {
		#util > .inline-block;
	}
}

.page.settings,
.page.manage {
	.page-sidebar {
		box-sizing: border-box;
		width: 20%;
		max-width: 340px;
		padding: 0 16px;
		&.sidebar-left {
			padding-left: 0;
		}
		&.sidebar-right {
			padding-right: 0;
		}
		& + .page-flow-area {
			width: 80%;
			max-width: 1200px;
		}
		&.sidebar-import-buffer {
			.help-box-sidebar {
				margin-top: 45px;
			}
		}
	}
}

.sidebar-wide {
	min-width: 220px;
}

#list-tag.page {
	td.column-name {
		padding-left: 22px;
		font-weight: 500;

		.behavior-remove {
			margin-left: -43px;
		}
	}
}

#users.page {
	.list-standard-topper {
		color: @grey-dk;
		display: flex;
		align-items: center;
		padding: 0 8px;

		font-size: 15px;
		line-height: 19px;

		.buttons {
			right: 8px;
			top: initial;
		}

		.team-name {
			margin-right: 8px;
		}
		.team-name .value {
			line-height: 17px;
		}
		.team-limited {
			font-size: @tiny;
		}
	}

	// Styles when RBAC is _not_ enabled - can be safely deleted when flag is flipped
	.page-flow-area:not(.has-rbac) {
		.list-standard-topper {
			padding: 0 15px;

			.buttons {
				right: 15px;
				top: initial;
			}
		}

		td.column-name {
			padding-left: 22px;
			font-weight: 500;

			&.is-admin {
				padding-left: 4px;
				.icon {
					float: left;
					background: url('/include/images/icons/card/icon-key.svg');
					width: 16px;
					height: 16px;
					margin: 1px 3px 0 0;
				}
			}

			.behavior-remove {
				margin-left: -43px;
			}
		}
	}

	// Styles when RBAC _is_ enabled - wrapping selector can be safely deleted when flag is flipped
	.page-flow-area.has-rbac {
		table.list-standard tr td:first-child,
		tr.list-standard-header th:first-child,
		tr.section-header th,
		tr.list-standard-header th {
			padding: 6px 8px;
		}

		table.list-standard tr td:first-child.link-container {
			padding: 0 0 0 8px;
		}

		table.list-standard tr td {
			padding: 6px 9px;
		}

		table.list-standard tr td:first-child.user-assign-container {
			padding: 0;
		}

		td.column-name {
			font-weight: 500;
		}

		.behavior-remove {
			margin-left: -29px;
		}
	}

	td.column-email {
		max-width: 160px;

		#util > .excerpt-crop > .ellipsis;
	}

	th.column-2fa,
	th.email-sync {
		width: 73px;
	}

	td.column-2fa,
	td.email-sync {
		text-align: center;
		color: @green; /* TODO: standardize color */

		a {
			text-decoration: none;
		}

		&.email-ingestion-disabled {
			i {
				// hide both icons when disabled
				display: none;
			}
		}

		&.email-ingestion-enabled {
			i.fa-warning {
				// hide the warning icon when enabled
				display: none;
			}
		}

		&.with-error {
			color: @warning;
			i.fa-check {
				// hide the check when there's an error
				display: none;
			}
			i.fa-warning {
				// show the warning icon when there's an error
				display: initial;
			}
		}
	}

	td.column-teams,
	td.column-benchmark {
		color: @grey; /* TODO: standardize color */
	}

	td.user-assign-container {
		padding: 0;
	}
	.capsule-add-container {
		display: none;
		padding: 3px 0 7px 12px;
		width: 180px;

		ul {
			margin-left: 12px;
		}
	}
}

#list-outcome {
	.auto-close-outcome {
		border-radius: 2px;
		padding: 4px;
		&.blink-success {
			background-color: @green-lt;
		}
		&.blink-error {
			background-color: @rose-lt;
		}
	}
}
#exchange {
	.flash-messages {
		margin-left: 260px;
	}

	#contacting-server {
		float: right;
		opacity: 0;
		background: url('@{img-root}/loading/white-42.gif') center center no-repeat;
		width: 42px;
		height: 42px;
		z-index: 9001;
	}
}
#list-export .column-download-link a {
	text-decoration: underline;
	color: @blue;
}

#uservoice {
	.token {
		background-color: @off-white-dk;
		font-size: 11px;
		padding: 11px;
		text-align: center;
	}

	ul.arrow-list a {
		color: @blue;
		img {
			display: block;
			max-width: 500px;
		}
	}
}

pre.token {
	background-color: @off-white-bg;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	padding: 15px;
}

.popover-list-webhooks {
	.action-webhook-ping {
		padding: 8px;
		line-height: 14px;
		font-size: 12px;
		float: right;
	}
	.webhook-ping-status {
		font-style: italic;
		line-height: 24px;
		font-weight: 500;

		&.variant-green {
			color: @green-dk;
		}
		&.variant-red {
			color: @rose-dk;
		}
	}
}

.list-webhooks {
	.column-url {
		max-width: 100px;
		color: @grey-lt;
		#util > .excerpt-crop > .ellipsis();
	}
}

.popover-list-destination {
	pre {
		display: inline;
		background-color: @off-white-dk;
	}
}

.unbounce-pages {
	.list-row {
		height: 38px; /* Prevent height change when spinner turns into button */
	}
}

// verified senders
.list-row-verified-sender {
	td.column-addedby {
		color: @grey-lt;
	}
	.status-new {
		color: @brown-dk;
	}
	.status-verified {
		color: @green;
	}
	.status-failed {
		color: @red;
	}
	.status-verifying {
		color: @warning;
	}
}

.list-row-hidden {
	// height: 0;
	// display: block;
	// opacity: 0;
	// pointer-events: none;
	// transition: all 200ms ease-in-out;
	background-color: white !important;

	> td {
		padding: 16px 16px 32px 16px !important;
		border-bottom: 1px solid @off-white-dk;
	}
}

.list-row-configurable {
	.button-expand,
	.button-collapse {
		color: @blue;
		&:hover {
			color: @blue-dk;
			text-decoration: underline;
		}
	}

	.button-collapse {
		display: none;
	}

	&.expanded {
		.button-collapse {
			display: block;
		}
		.button-expand {
			display: none;
		}

		padding-bottom: 24px;
	}
}

.popover-list-sender-address {
	dl.form-standard {
		dt {
			width: 90px;
		}
		dd {
			margin-left: 98px;
			input.type-text {
				width: 170px;
			}
		}
	}
}
