:root {
    --plan-comparison-column-spacing: 16px;
    --plan-comparison-column-background-color: #fffaf5; /* orange-lt */
    --plan-comparison-hover-background-color: #faf0e6; /* orange-xlt */
}

/* Default the entire table background to orange */
.plan-comparison-table-new {
    background-color: var(--plan-comparison-column-background-color);
}

.sticky-header {
    position: sticky;
    background-color: white;
    top: 0;
    border-bottom: 1px solid var(--orange);
    z-index: 9; /* Ensure it is above the table but below the header */
}

.table-cell--feature-badge {
    padding: 16px 0;
    border-left: 16px solid white;
    text-align: center;
    max-width: 180px;
}

.table-row--feature-group-header td {
    padding: 32px 8px 8px 8px;
    font-size: 17px;
    font-weight: 500;
    color: var(--grey-dk);
    text-align: left;
}

.table-cell--feature-group-title {
    background: white;
}

.table-row--feature:hover .table-cell--feature-name {
    background-color: var(--plan-comparison-column-background-color);
}

.table-row--feature .table-cell--feature-name-text {
    color: var(--grey);
    font-size: 15px;
    line-height: 21px;
}

.table-row--feature:hover .table-cell--feature-name-text {
    cursor: pointer;
    color: var(--grey-dk);
    border-bottom: 2px dotted #1d1c1d;
}

.table-row--feature:hover .table-cell--feature-details {
    border-left: 16px solid var(--plan-comparison-column-background-color);
    background-color: var(--plan-comparison-hover-background-color);
}

.table-cell--feature-group-title,
.table-cell--feature-name {
    width: 250px !important;
    box-sizing: border-box;
}

.table-cell--feature-name {
    padding: 8px 64px 8px 8px;
    max-width: 200px;
    border-left: none;
    background-color: white;
    text-align: left;
}

.table-cell--feature-details,
.table-cell--spacer,
.table-cell--feature-badge {
    padding: 8px 0;
    border-left: 16px solid white;
    text-align: center;
    min-width: 120px;
    max-width: 180px;
}
