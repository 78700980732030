.plan-field-input input {
    width: 50%;
}

.invoice-preview {
    float: right;
    position: sticky;
    top: 8px;
    height: fit-content;
}

.text-field {
    color: var(--grey-dk);
    padding: 4px 8px;
    height: 34px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    transition: color 200ms linear;
    border: 2px solid var(--off-white-dk);
    border-radius: 3px;
    text-align: right;
}

.text-field:not(:disabled):hover {
    border-color: var(--grey-lt);
}

.text-field:not(:disabled):focus,
.text-field:not(:disabled):active {
    border-color: var(--blue);
}

.text-field--error {
    composes: text-field;
    border-color: var(--rose);
}

.banner-container {
    display: block;
    width: 962px;
}

.form {
    z-index: 1;
}
