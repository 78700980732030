.normal-font {
    font-size: var(--font-normal);
    line-height: 19px;
}

.small-font {
    font-size: var(--font-small);
    line-height: var(--font-big);
}

.text-container {
    width: 30%;
    max-height: 80px;
    overflow: hidden;
}

.approval-container {
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 3px;
    background-color: var(--quote-signature-orange);
    text-align: center;
}

.line-container {
    border-top: 1px solid black;
    text-align: center;
    width: 40%;
}

.logo-container {
    width: 25%;
}

.subtotal-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 54px); /* 54px is the height of the total container */
    padding: 16px;
}

.total-container {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: var(--off-white-dk);
    display: flex;
    justify-content: space-between;
    height: 54px;
    align-items: center;
    padding: 0 16px;
}

.quote-info-container {
    min-height: 88px;
    width: 100%;
    height: 100%;
}

.header-container {
    height: 200px;
    margin-bottom: 16px;
}

.body-subtotal-container {
    min-height: 300px;
    display: flex;
    gap: 32px;
    width: 100%;
}

.body-subtotal-container-template-height {
    min-height: 200px;
}

.prepared-container {
    min-height: 300px;
    display: flex;
    gap: 32px;
    width: 100%;
}

.prepared-container-template-height {
    min-height: 167px;
}

.half-section {
    width: 100%;
    /* 16px is the gap between the sections */
    max-width: calc(50% - 16px);
    min-width: calc(50% - 16px);
    overflow-wrap: break-word;
}

.add-row-button {
    margin-top: 8px;
    display: flex;
    width: 70%;
}

.terms-container {
    height: 160px;
    text-align: center;
}

.quote-overview-container {
    display: flex;
    /* width of the whole modal (100%) + margin of 32px */
    width: calc(100% + 32px);
    height: 64px;
    background-color: var(--off-white-dk);
    margin-left: -32px;
    padding: 16px;
}

.action-menu-container {
    padding-top: 10px;
}

/* This div is just used as a ref, but some peanut styling is forcing 100% width
and height, so we wrap it in 0px by 0px div */
.quote-script-container {
    height: 0%;
    width: 0%;
}

.header-controls-container {
    width: 150px;
    display: flex;
    align-self: center;
    margin-left: auto;
}
