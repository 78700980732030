.item-type {
    width: 80px;
}

.price {
    text-align: right;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 34px;
}

.delete-icon {
    position: absolute;
    right: 10px;
    top: 2px;
}

.dropdown {
    width: 60px;
}

.value {
    width: 64px;
}
