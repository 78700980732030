.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    align-items: center;
    border-top: 1px solid var(--off-white-dk);
    padding-top: 100px;
}

.graphic {
    width: 300px;
    margin-bottom: 32px;
}

.header {
    font-size: var(--font-big);
    color: var(--grey-dk);
    margin-bottom: 16px;
}

.subheader {
    font-size: var(--font-normal);
    color: var(--grey);
    margin: 0;
}

.subheader a {
    color: var(--blue);
}

.subheader a:hover {
    color: var(--blue-dk);
}
