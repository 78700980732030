.invoice-preview {
    width: 350px;
    flex-shrink: 0;
}

.invoice-preview--loading {
    composes: invoice-preview;
    height: 300px;
    margin-bottom: 16px;
}
