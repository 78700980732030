#page-content {
	padding: 0;
}

.ui-internal-controller__main {
	padding: 16px;

	a {
		text-decoration: underline;
		color: @blue-dk;
	}
}

.ui-internal-controller--page-header {
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-grow: 1;
	align-items: center;
	padding: 16px;
	border-bottom: 2px solid @off-white-dk;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

	a {
		text-decoration: underline;
		color: #fe5800;
	}
}

.ui-internal-controller--page-header__back-button {
	display: block;
	font-size: 15px;
	color: @blue;

	&:hover {
		color: @blue-dk;
		text-decoration: underline;
	}
}

.ui-internal-controller--page-header__name-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
}

.ui-internal-controller--page-header__name {
	display: inline-block;
	font-size: 23px;
	color: @grey-dk;
	letter-spacing: 3px;
}
