.status-label {
    width: 102px;
    display: flex;
    gap: 4px;
    position: relative;
    height: 32px;
}

.status-label--disabled {
    pointer-events: none;
}

.status-label-content {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    z-index: 1;
    padding-left: 12px;
}

.background-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 102px;
    height: 32px;
    z-index: 0;
    opacity: 0.4;
}

.background-wrapper--active {
    opacity: 1;
}

.background-wrapper--draft-status {
    color: var(--grey-lt);
}

.background-wrapper--published-status {
    color: var(--blue);
}

.background-wrapper--accepted-status {
    color: var(--green);
}
