.section-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 90px;
    background-color: white;
    padding: 8px 16px;
    box-sizing: border-box;
    overflow: auto;
    white-space: pre-wrap;
}
