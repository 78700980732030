.peep-container .peep-sidebar {
	#util > .border-box;
	margin: 0;
	padding: 0;
	width: @peep-right-sidebar-width;
	max-width: none;

	border-left: 1px solid @off-white-dk;
	box-shadow: -1px 0 4px rgba(0, 0, 0, 0.08);

	-webkit-box-flex: 0;
	-webkit-box-shrink: 0;
	-webkit-flex-basis: @peep-right-sidebar-width;
	flex: 0 0 @peep-right-sidebar-width;

	-webkit-box-ordinal-group: 2;
	-moz-box-ordinal-group: 2;
	-ms-flex-order: 2;
	-webkit-order: 2;
	order: 2;

	&.state-fixed {
		.peep-sidebar-scroll-container {
			position: fixed;
			top: 0;
			height: 100%;
			width: @peep-right-sidebar-width;
			padding-top: 68px;
		}

		.peep-account-details-react-mount {
			// These fields can't clash with the fixed sidebar entity description card if they don't exist
			display: none;
		}

		.peep-sidebar-top { top: 0; }
	}

	&.state-new-information {
		.action-new-information {
			opacity: 0.4;
		}
		.peep-sidebar-add {
			max-height: none;
			padding: 9px 5px 5px 9px;
		}
	}
	&.state-submitting {
		.peep-sidebar-add .eip-buttons { opacity: 0.3; }
	}

	.peep-sidebar-header {
		background-color: white;

		& > div {
			margin-bottom: 24px;
		}
	}

	.peep-sidebar-scroll-container {
		#util > .border-box;
		position: relative;
		// The top -70px and padding 70px cancel each other out visually, but behind
		// the scenes, this leaves enough room for the "delete confirmation" popmini
		// to display above the first address field within the scrollable sidebar.
		top: -70px;
		padding: 70px 32px 20px 32px;
		width: @peep-right-sidebar-width;
		height: 100%;
		overflow-y: auto;
		transition: padding-top .5s ease;
	}

	.peep-sidebar-top {
		display: block;
		#util > .border-box;
		position: absolute;
		top: -51px;
		left: 0;
		width: 100%;
		height: 51px;
		border-bottom: 1px solid @off-white-dk;
		transition: all .5s ease;

		.peep-icon-container {
			float: left;
			width: 50px;
			height: 50px;
			margin-right: 10px;
			background: transparent url('@{img-root}/icons/avatars/icon-account.svg') no-repeat center;
			background-size: 48px 48px;
		}

		.parent-account-avatar {
			width: 50px;
			height: 50px;
			background: transparent url('@{img-root}/icons/avatars/icon-account-parent.svg') no-repeat 50% 50%;
			background-size: 35px 35px;
			margin-top: 7px;
			margin-left: 7px;
		}

		.peep-description-container {
			max-width: 200px;
			float: left;
			margin-top: 8px;
			p {
				#util > .excerpt-crop > .ellipsis;
				font-family: @headline-font;
				margin: 0;
				font-size: @normal;
				line-height: 18px;

				&.description {
					font-size: @tiny;
					font-family: @body-font;
				}
			}
		}
	}

	.action-new {
		display: block;
		float: none;
		margin-bottom: 6px;
	}
	.peep-sidebar-blocks { // esp. for adding space before custom fields
		margin-bottom: 32px;
	}
	.peep-sidebar-block {
		margin-bottom: 20px;
		border-top: 1px dotted @off-white-dk;
		&.state-empty {
			display: none;
		}
		&.first-visible-child {
			border-top: none;
		}
	}
	.eip-buttons { float: right; }
	.eip-field {
		padding: 12px 0;

		.action-delete {
			right: -16px;
		}

		.icon {
			display: none;
			float: left;
			margin-left: 2px;
			margin-right: 4px;
			font-size: 20px;
		}

		&.type-twitter {
			.twitter-description {
				font-size: 12px;
				font-style: italic;
				color: @grey-lt;
				margin: 4px 6px;
			}
		}

		&.has-icon {
			.icon {
				display: inline-block;
				width: 20px;
			}
		}

		&.type-twitter .icon { color: @twitter-blue; }
		&.type-facebook .icon { color: @facebook-blue; }
		&.type-linkedin .icon { color: @linkedin-blue; }
		&.type-google-plus .icon {
			color: @google-plus-red;
			// The google+ icon is considerably wider, so reducing size
			font-size: 17px;
		}
		&.type-youtube .icon { color: @youtube-red; }
		&.type-foursquare .icon { color: @foursquare-pink; }
		&.type-pinterest .icon { color: @pinterest-red; }
		&.type-flickr .icon { color: @flickr-pink; }
		&.type-vimeo-square .icon { color: @vimeo-green; }
		&.type-tumblr .icon { color: @tumblr-blue; }
		&.type-dribble .icon { color: @dribble-pink; }
		&.type-angellist .icon { color: @angellist-black; }
		&.type-behance .icon { color: @behance-blue; }
		&.type-delicious .icon { color: @delicious-blue; }
		&.type-digg .icon { color: @digg-black; }
		&.type-github .icon { color: @github-green; }
		&.type-hacker-news .icon { color: @hacker-news-orange; }
		&.type-instagram .icon { color: @instagram-blue; }
		&.type-lastfm .icon { color: @lastfm-red; }
		&.type-reddit .icon { color: @reddit-orange; }
		&.type-twitch .icon { color: @twitch-purple; }
		&.type-vine .icon { color: @vine-green; }

		&.type-url {
			.subvalue {
				float: left;
				line-height: 20px;
			}
		}

		&.type-emails {
			.status {
				&.status-blocklisted {
					.type-blocklisted {
						display: inline-block;
					}
				}
				&.status-internal {
					.type-internal {
						display: inline-block;
					}
				}
			}

			.status-badge {
				display: none;
				margin-top: 12px;
				padding: 2px 8px;
				border-radius: 3px;
				font-size: 13px;
				cursor: default;

				&.type-blocklisted {
					background-color: #fff7f7;
    				color: #bf2436;
				}
				&.type-internal {
					background-color: #fff5e5;
    				color: #ea7134;
				}
			}
		}
	}

	.peep-sidebar-add {
		max-height: 0;
		overflow: hidden;
		border-bottom: 1px dotted @off-white-dk;
		input {
			display: block;
			#util > .border-box();
			width: 100%;
			height: 24px;
			font-size: 11px;
		}
	}
}

.peep-container {
	&.entity-contacts {
		.peep-sidebar-top {
			background-color: @navy-lt;
			.peep-icon-container {
				background-position: -45px 0;
			}
		}
	}

	&.entity-accounts {
		.peep-sidebar-top {
			background-color: @plum-lt;
			.peep-icon-container {
				background-position: 5px 0;
				img { display: none; }
			}
		}
	}
}
