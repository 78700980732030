.rich-text-container {
    height: 100%;
}

.rich-text-container ul {
    list-style-type: disc;
    padding-left: 1.5em;
    height: 100%;
    word-break: break-word;
}

.normal-font {
    font-size: var(--font-normal);
    line-height: 19px;
}

.small-font {
    font-size: var(--font-small);
    line-height: var(--font-big);
}

.center-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
