.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* Need enough room for the save/cancel buttons
       without pushing the content down */
    min-height: 35px;
}

.component-children {
    border: none;
    background: none;
    width: 100%;
    height: 100%;
    display: flex;
    outline: none;
    justify-content: center;
    align-items: flex-start;
}
