.container {
    color: var(--grey-lt);
    margin-right: 40px;
}

.title {
    margin: 25px 0 10px 0;
    font-size: 17px;
    line-height: 17px;
    color: var(--grey-dk);
    font-weight: normal;
}

.subtitle {
    color: var(--grey-lt);
}

.item-container {
    margin-top: 16px;
}

.warning {
    padding-bottom: 4px;
}

.bullet-container {
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bullet-x {
    color: var(--rose);
}

.item-text {
    margin-left: 8px;
    height: 16px;
    color: var(--grey-dk);
    font-size: 12px;
}
