@value threadHeaderHeight: 73px;
@value threadListWidth: 300px;
@value channelHeaderHeight: 73px;

.page-container {
    position: relative;
    display: flex;
    height: 100%;
}

.content-container {
    width: 100%;
    display: flex;
}

.thread-list-container {
    border-right: 1px solid var(--off-white-dk);
    height: 100%;
    width: threadListWidth;
}

.thread-list-header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0 16px;
    justify-content: center;
    border-bottom: 1px solid var(--off-white-dk);
    height: 72px;
}

.thread-list {
    height: calc(100% - threadHeaderHeight);
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.thread-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - threadListWidth);
}

.thread-header {
    border-bottom: 1px solid var(--off-white-dk);
    height: threadHeaderHeight;
    box-sizing: border-box;
}

.channel-container {
    height: calc(100% - channelHeaderHeight);
}

/* Used to avoid double borders without causing horizontal scrolling */
.thread-list-item-wrapper > div:first-child {
    border-top: none;
    border-right: none;
}
