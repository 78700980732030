.quote-text-area {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.quote-text-area--background-white {
    background-color: white;
}

.quote-text-area--pad-16 {
    padding: 16px;
}

.quote-text-area--pad-8 {
    padding: 8px;
}

.quote-text-area :global(.ql-toolbar) {
    border: none;
    background-color: var(--quote-toolbar-background);
    /* Let's us render the toolbar inside of the padding. Specifically for
    padding of 16px */
    position: absolute;
    left: -16px;
    bottom: -16px;
    width: calc(100% + 32px);
}

.quote-text-area :global(button) {
    fill: white;
    border-radius: 3px;
    /* Centers the icons inside the buttons */
    padding: 3px 7px !important;
}

.quote-text-area :global(button:not(:disabled)) {
    background-color: var(--quote-toolbar-button-background);
}

.quote-text-area :global(button:not(:disabled):hover) {
    background-color: var(--quote-toolbar-button-background);
    fill: var(--grey-lt);
}

.quote-text-area :global(button.ql-active) {
    background-color: var(--quote-toolbar-button-background);
    fill: var(--blue);
}
