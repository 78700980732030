.addon-content {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0 0 0;
    list-style-type: disc;
}

.line-item {
    display: flex;
    width: 50%;
    align-items: center;
    margin-bottom: 8px;
    box-sizing: border-box;
    color: var(--grey-dk);
    font-size: var(--font-normal);
}

.line-item::before {
    content: '•';
    margin-right: 8px;
    font-size: 20px;
    color: var(--orange);
}

.line-item:nth-child(even) {
    padding-left: 32px;
}

.line-item > :first-child {
    margin-right: 16px;
}
