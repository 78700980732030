.table-cell {
    composes: cell from '../styles.css';
}

.border--right {
    border-right: var(--border-hairline);
}

.border--left {
    border-left: var(--border-hairline);
}

.data-type--right-align {
    text-align: right;
    justify-content: flex-end;
}

.data-type--center-align {
    text-align: center;
    justify-content: center;
}

.compact {
    padding: 8px;
}

.edit-in-place {
    padding: 0;
}

.truncated {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.text-is-dk-grey {
    color: var(--grey-dk);
}
