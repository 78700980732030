.container {
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;
    box-shadow:
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12),
        0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.option-container a {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    min-height: 40px;
    height: 40px;
    min-width: 150px;
    font-size: 15px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    color: var(--grey-dk);
}

.option-container:hover a {
    cursor: pointer;
    background-color: var(--color-list-hovered);
}
