.container {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 240px;
    padding: 16px;
    overflow: auto;
}

.container > div:last-child {
    height: fit-content;
}
