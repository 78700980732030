.sales-plan-form {
    margin-bottom: 16px;
}

.plan-field-input input {
    width: 50%;
}

.plan-controls {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
}

.right-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 230px;
}

.quantity-and-price {
    margin-top: 16px;
    margin-bottom: 32px;
}
