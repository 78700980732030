.container {
    background-color: var(--white);
    position: absolute;
    bottom: 48px;
    left: 0;
    border-top: 1px solid var(--form-border);
    padding: 24px;
    width: 100%;
    box-sizing: border-box;
}
