.links-list {
    border: 1px solid var(--off-white-dk);
    border-top: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.links-list > *:not(:last-child) {
    border-bottom: 1px solid var(--off-white-dk);
}

.link-content {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    color: var(--grey);
    font-size: var(--font-small);
    line-height: 17px;
}

.link-content:hover {
    background-color: var(--color-list-hovered);
    color: var(--grey-dk);
}

.link-content--active {
    composes: link-content;
    background-color: var(--color-list-selected);
}

.link-content--active:hover {
    background-color: var(--color-list-selected);
    cursor: default;
}

.link-content--blue {
    composes: link-content;
    background-color: var(--color-list-selected);
}

.link-content--blue > :first-child {
    /* only link label */
    color: var(--blue);
}

.link-content--blue:hover > :first-child {
    /* only link label */
    color: var(--blue-dk);
}

.right-container {
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 8px;
}

.chevron-container {
    margin-top: 1px;
    color: var(--placeholder);
}

.link-content:hover .chevron-container,
.link-content--active .chevron-container {
    color: var(--grey);
}

.loading-container > * {
    box-sizing: border-box;
}
