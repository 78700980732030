.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container img {
    height: 64px;
}

.content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 164px;
}

.empty-text {
    margin-left: 32px;
    max-width: 500px;
}

.empty-text > *:first-child {
    margin-bottom: 4px;
}
