.pathname-field {
    position: relative;
}

.domain-name-text {
    position: absolute;
    top: 9px;
    left: 8px;
    padding-right: 8px;
    border-right: 1px solid var(--off-white-dk);
}

.domain-name-text--with-buttons {
    padding-right: 8px;
    padding-left: 8px;
    border-right: 1px solid var(--off-white-dk);
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-width: min-content;
    white-space: nowrap;
}
