.input-container {
    display: flex;
    gap: 16px;
    width: 65%;
    box-sizing: border-box;
}

.input {
    width: 50%;
    box-sizing: border-box;
    height: calc(100% - 24px);
}
