.flash-banner {
    composes: flash-banner from './tabbed-signup-form.css';
}

.right-section {
    display: flex;
    flex-direction: column;
    width: 350px;
    margin-top: 16px;
}

.right-section > :global(.billing-form-wrapper) {
    padding: 0 !important;
    margin-top: 0 !important;
}
