.plan-notice-wrapper {
    margin: 8px 0 24px 0;
    font-size: var(--font-small);
}

.plan-notice-wrapper > button {
    padding: 0;
}

.chevron {
    margin-left: 4px;
    display: flex;
    align-items: center;
    font-size: var(--font-tiny);
    color: var(--grey);
}

.plan-notice-text {
    background-color: var(--yellow-lt);
}

.plan-notice-details {
    margin: 8px 0 32px 0;
    padding: 16px;
    border: 1px solid var(--off-white-dk);
    background-color: var(--off-white);
}
