.link-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 14px;
    margin-right: 12px;
}

/********
 * Link item (needs to be on the a tag)
 *********/
.link-item a {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    color: var(--white);
    font-size: var(--font-normal);
    border-radius: 3px;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    margin: 4px 0;
}

.link-item:first-child a {
    margin-top: 8px;
}

.link-item:last-child a {
    margin-bottom: 8px;
}

/* Hover states */
.link-item:not(.link-item--active) a:hover {
    background-color: var(--navy-dk-new);
}

.link-item--embedded:not(.link-item--active) a:hover .link-item-submenu-line.embedded,
.link-item:not(.link-item--active) a:hover .link-item-submenu-line {
    background-color: var(--navy-lt-new);
}

/* Focus states */
.link-item:not(.link-item--active) a:focus-visible:not([disabled]) {
    background-color: var(--navy-dk-new);
}

.link-item a:focus-visible:not([disabled]) {
    outline: 2px solid var(--focus-blue);
    outline-offset: 0;
    background-color: var(--navy-xlt-new);
}

.link-item:not(.link-item--active) a:focus-visible:not([disabled]) .link-item-submenu-line {
    background-color: var(--navy-lt-new);
}

.link-item--embedded:not(.link-item--active) a:hover {
    background-color: var(--rcfx-sidebar-hover);
}

.link-item--embedded:not(.link-item--active) a:hover .link-item-submenu-line {
    background-color: var(--rcfx-sidebar-hover);
}

.link-item--embedded:not(.link-item--active) a:focus-visible:not([disabled]) {
    background-color: var(--rcfx-sidebar-hover);
}

/* Active state (when on this page) */
.link-item--active a {
    color: var(--sidebar-primary);
    background-color: var(--white);
}

.link-item--active .link-item-icon svg {
    color: var(--sidebar-primary);
    fill: var(--sidebar-primary);
}

.link-item a[disabled] {
    pointer-events: none;
}

.link-item--submenu-item {
    display: flex;
    align-items: center;
    margin-left: 24px;
}

.link-item--submenu-item a {
    position: relative;
    margin-left: 4px;
}

.link-item-submenu-line {
    position: absolute;
    top: -4px;
    left: -8px;
    width: 2px;
    height: calc(100% + 8px);
    background-color: var(--navy-dk-new);
    flex-shrink: 0;
}

.link-item-submenu-line.embedded {
    background-color: var(--rcfx-sidebar-hover);
}

.link-item-submenu-line.embedded:hover {
    background-color: var(--rcfx-sidebar-hover);
}

.link-item--submenu-item.link-item--active .link-item-submenu-line {
    background-color: var(--navy-xlt-new);
}
