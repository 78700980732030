.active-promotion-banner {
    padding: 16px;
    background-color: var(--plum-lt);
    display: flex;
    align-items: center;
}

.active-promotion-banner > :first-child {
    margin-right: 16px;
}

.promotion-text {
    display: flex;
    flex-direction: column;
    color: var(--grey);
    font-size: 15px;
}

.promotion-text > :first-child {
    margin-bottom: 8px;
    font-size: 17px;
    color: var(--grey-dk);
}
