.custom-message {
    margin: 32px 0 48px;
}

.plan-description {
    font-weight: 500;
    font-size: 15px;
    margin: 12px 0;
}

.contact-team {
    padding: 0;
}
