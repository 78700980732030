.container {
    display: inline-block;
    position: relative;
    z-index: 10;
    box-shadow: none;
    flex-shrink: 0;
}

.container--border {
    composes: container;
    border: 2px solid var(--white);
    background-color: var(--white);
}
/*
If this container is empty (.container:empty), and not displaying anything, you
need to check the renderAvatarContents method in avatar.jsx to make sure
you aren't returning null.
*/
.container:empty {
    display: none;
}

.container:before,
.container .initials,
.container .initials--disabled,
.container img,
.container svg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.container img {
    z-index: 10;
}

.initials,
.initials--disabled {
    color: white;
    cursor: default; /* no text bar */
    text-align: center;
    text-transform: uppercase;
}

.type-users .initials,
.type-teams .initials {
    background-color: var(--orange-dk);
}

.type-contacts .initials {
    background-color: var(--navy-dk);
}

.type-accounts .initials,
.type-mcfxCompanies .initials {
    background-color: var(--plum-dk);
}

.type-leads .initials {
    background-color: var(--green-dk);
}

.type-unknown .initials {
    background-color: var(--grey-dk);
}

.initials--disabled {
    background-color: var(--off-white-dk);
}
