.coupon-section {
    margin-bottom: 16px;
}

.coupon-details {
    display: block;
}

.coupon-status {
    margin-top: 8px;
    font-size: var(--font-normal);
    color: var(--grey);
}

.coupon-invalid {
    color: var(--rose);
}

.coupon-valid {
    color: var(--green);
}
