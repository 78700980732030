.secondary-sidebar-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
    height: 100%;
    background-color: var(--sidebar-primary);
    box-sizing: border-box;
    flex-shrink: 0;
}

.secondary-sidebar-wrapper.embedded {
    width: 200px;
    height: 100%;
    margin-top: 0;
    background-color: var(--rcfx-sidebar);
    box-sizing: border-box;
    flex-shrink: 0;
}

.secondary-sidebar {
    background-color: var(--sidebar-secondary);
    border-radius: 8px 0 0 0;
    overflow: hidden;
    box-sizing: border-box;
    padding: 8px;
    height: 100%;
    width: 100%;
}

.secondary-sidebar.embedded {
    background-color: var(--rcfx-sidebar);
}

.secondary-sidebar-wrapper.embedded .secondary-sidebar {
    border-radius: 0;
}

.secondary-sidebar > li:first-child a {
    margin-top: 0;
}

.footer {
    width: 100%;
    background-color: var(--sidebar-secondary);
}

.footer.embedded {
    background-color: var(--rcfx-sidebar);
}

/* My twin lives in main-content-loading.phtml */
.nut-secondary-sidebar--loading-bar {
    width: 100%;
    background-color: #333557;
    height: 33px;
    margin-bottom: 4px;
    border-radius: 4px;
}
