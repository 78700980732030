.link-section {
    display: grid;
    grid-template-columns: 430px auto;
    gap: 16px;
}

.customize-link {
    display: flex;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    padding: 2px 2px 2px 4px;
    justify-content: space-between;
}

.pathname-field {
    display: flex;
    flex-direction: column;
    width: 86%;
}

.url {
    display: flex;
    align-items: center;
    width: 75%;
}
