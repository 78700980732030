.search-bar {
    position: relative;
    width: 100%;
    height: 35px;
    background-color: white;
}

.bordered {
    composes: search-bar;
    composes: border from '../../../../client_modules/shells/react/components/border/border.css';
}

.no-border {
    composes: search-bar;
    border: 1px solid transparent;
}

.search-container {
    position: absolute;
    left: 0;
    height: 100%;
    width: 35px;
    margin-top: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-container--small {
    composes: search-container;
    width: 24px;
}

.search-icon {
    font-size: 17px;
    color: var(--icon-secondary);
}

.input {
    /* Inset input to allow border radius */
    margin: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);

    padding: 0 0 0 35px;
    vertical-align: baseline;
    box-sizing: border-box;
    border: none;
    outline: none;
    background: white;
}

.input input {
    background-color: transparent;
    height: 100%;
    padding-left: 0;
    margin-left: -1px;
}

.input:focus::placeholder {
    color: var(--off-white-dk);
}

.input--small {
    composes: input;
    padding: 0 0 0 24px;
}

.clear {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 16px;
}

.clear--small {
    composes: clear;
    padding: 0 8px;
}

.search-bar--dark {
    composes: search-bar;
    background-color: #242854;
    color: #fff;
}

.bordered--dark {
    composes: search-bar--dark;
    composes: border from '../../../../client_modules/shells/react/components/border/border.css';
    border-color: rgba(245, 252, 255, 0.15);
}

.bordered--dark:not(:disabled):hover {
    border-color: rgba(255, 255, 255, 0.5);
}

.no-border--dark {
    composes: search-bar--dark;
    border: 1px solid transparent;
}

.input--dark {
    composes: input;
    background: var(--sidebar-secondary);
}

.input--dark input {
    background-color: transparent;
    color: var(--white);
}

.input--small--dark {
    composes: input--dark;
    padding: 0 0 0 24px;
}

.search-icon--dark {
    composes: search-icon;
    color: var(--icon-secondary-dark);
}

.search-container--dark {
    composes: search-container;
    color: var(--icon-secondary-dark);
}

.search-container--small--dark {
    composes: search-container--dark;
    width: 24px;
}
