.user-quantity {
    margin-bottom: 32px;
}

.user-quantity input {
    width: 80px;
}

.inline-form {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 4px;
}
