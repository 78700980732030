.cycle-section {
    padding: 24px 0;
    border-bottom: 1px solid var(--off-white-dk);
}

.cycle-section:last-child {
    border-bottom: none;
    padding-bottom: 8px;
}

.subscription-section {
    font-size: var(--font-big);
    color: var(--grey-dk);
}

.subscription-section:not(:first-child) {
    border: none;
    margin-top: 16px;
}

.total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    font-size: var(--font-big);
    font-weight: 500;
    color: var(--grey-dk);
}

.total--updated {
    composes: total;
}

.total--updated .cost {
    color: var(--green);
}
