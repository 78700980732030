.product-name {
    color: var(--grey-dk);
    font-size: var(--font-normal);
    margin-right: 8px;
}

.cost {
    display: inline-block;
    margin-left: 3px;
    color: var(--grey-dk);
    font-size: var(--font-normal);
}

.sticker-cost {
    composes: cost;
    text-decoration: line-through;
    color: var(--grey-lt);
    margin-right: 4px;
    font-size: var(--font-small);
    font-style: italic;
}

.cost--cancelled {
    composes: cost;
    font-style: italic;
    color: var(--grey-lt);
}

.quantity {
    padding-top: 8px;
    font-size: var(--font-normal);
    color: var(--grey-lt);
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

.enabled-qty {
    display: inline-block;
    margin-left: 3px;
    font-size: 12px;
}
